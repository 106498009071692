import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ALL_CONTACTS_TAKE, DEFAULT_PAGE_INDEX } from "../../const/const";
import { IFilter } from "../../interfaces/IFilter/IFilter";

export const initialState: IFilter = {
  pagination: {
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: ALL_CONTACTS_TAKE,
  },
  search: "",
  firstLetter: "",
  sortBy: [{ id: "", desc: false }],
};

const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setFilter(state, action: PayloadAction<IFilter>) {
      Object.assign(state, action.payload);
    },

    resetFilter(state) {
      state.pagination = { pageIndex: DEFAULT_PAGE_INDEX, pageSize: ALL_CONTACTS_TAKE };
      state.search = "";
      state.firstLetter = "";
      state.sortBy = [{ id: "", desc: false }];
    },
  },
});

export const { setFilter, resetFilter } = filterSlice.actions;
export default filterSlice.reducer;
