import { Button, ContactCardIcon, ContactGroupIcon, Flex, Input, ListIcon, MenuButton, SearchIcon, SettingsIcon, SplitButton } from "@fluentui/react-northstar";
import { useNavbar } from "./Navbar.logic";
import React, { memo } from "react";
import { primaryStyle } from "../../utils/primaryStyle";
import "./Navbar.scss";
import { IDisplayMode } from "../../interfaces/IDisplayMode";
import { InformationMenu } from "front";

const Navbar = memo(() => {
  const { t, menus, currentDisplayMode, register, createNewContact, canAddContact } = useNavbar();

  const renderIconByChoice = () => {
    if (currentDisplayMode == IDisplayMode.CARD) {
      return <ContactCardIcon />;
    } else if (currentDisplayMode == IDisplayMode.GROUP) {
      return <ContactGroupIcon />;
    }
    return <ListIcon></ListIcon>;
  };

  return (
    <Flex style={{ padding: "1.25rem" }} className="navbar-container">
      <Flex className="navbar-left">
        <SplitButton
          className="split-btn"
          primary
          menu={menus.addNewMenu}
          button={{
            content: `${t("New")} ${t("Contact")}`,
            disabled: !canAddContact,
          }}
          onMainButtonClick={createNewContact}
        />
        <MenuButton
          style={{ marginLeft: ".5rem" }}
          trigger={<Button primary styles={primaryStyle} icon={renderIconByChoice()} content={t(currentDisplayMode)} />}
          menu={menus.displayModeMenu}
          on="click"
        />
      </Flex>
      <Flex fill className="navbar-right" gap="gap.smaller">
        <div className="navbar-inp-container">
          <Input icon={<SearchIcon />} placeholder={t("SearchContact")} iconPosition="start" fluid inverted {...register("search")} />
        </div>
        <MenuButton trigger={<Button icon={<SettingsIcon outline />} iconOnly />} menu={menus.moreMenu} on="click" />
        <InformationMenu />
      </Flex>
    </Flex>
  );
});

export default Navbar;
