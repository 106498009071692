import { useEffect, useState } from "react";
import { IBreakpoint } from "../interfaces/IBreakpoint";
import { defaultBreakpoint } from "../utils/defaultBreakpoint";

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<IBreakpoint>(defaultBreakpoint(window.innerWidth));

  const handleResize = () => {
    const { innerWidth } = window;
    setBreakpoint(defaultBreakpoint(innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { breakpoint };
};
