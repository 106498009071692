import { ArrowDownIcon, ArrowUpIcon, Button, Flex, Skeleton } from "@fluentui/react-northstar";
import { memo } from "react";
import { useTable } from "./Table.logic";
import { flexRender } from "@tanstack/react-table";
import "./Table.css";
import { ChevronsLeft, ChevronsRight } from "lucide-react";

const Table = () => {
  const logic = useTable();

  if (logic.isLoading)
    return (
      <Flex vAlign="center" hAlign="center" space="between" style={{ marginTop: "2rem" }} fill>
        <Flex fill>
          <Skeleton animation="pulse">
            <Skeleton.Input style={{ minHeight: "50px", width: "100%" }} />
            <Skeleton.Input style={{ minHeight: "40px", width: "100%", marginTop: ".1rem" }} />
            <Skeleton.Input style={{ minHeight: "60px", width: "100%", marginTop: ".1rem" }} />
            <Skeleton.Input style={{ minHeight: "60px", width: "100%", marginTop: ".1rem" }} />
            <Skeleton.Input style={{ minHeight: "60px", width: "100%", marginTop: ".1rem" }} />
            <Skeleton.Input style={{ minHeight: "60px", width: "100%", marginTop: ".1rem" }} />
            <Skeleton.Input style={{ minHeight: "60px", width: "100%", marginTop: ".1rem" }} />
            <Flex hAlign="end">
              <Skeleton.Input style={{ minHeight: "20px", width: "175px", marginTop: ".25rem" }} />
            </Flex>
          </Skeleton>
        </Flex>
      </Flex>
    );

  return (
    <Flex style={{ margin: ".5rem 0 1rem 0" }} column>
      {logic.tableLength > 0 ? (
        <Flex fill style={{ width: "100%" }} column>
          <Flex fill style={{ width: "100%" }} column>
            <table className="table-table">
              <thead className="table-header">
                {logic.table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} onClick={() => header.column.toggleSorting(header.column.getIsSorted() === "asc")} className="table-head-th">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === "asc" ? (
                              <ArrowUpIcon size="smaller" style={{ marginLeft: ".25rem" }} />
                            ) : (
                              <ArrowDownIcon size="smaller" style={{ marginLeft: ".25rem" }} />
                            )
                          ) : (
                            <>{header.column.getCanSort() ? <ArrowDownIcon size="smaller" style={{ marginLeft: ".25rem" }} /> : <></>}</>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {logic.table.getRowModel().rows.map((row, i) => {
                  return (
                    <tr
                      key={row.id}
                      className={"table-rows"}
                      style={{ animation: `fadeIn calc(0.2s * ${i})` }}
                      onClick={() => logic.editContact(row.original.id, false)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                {logic.table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </table>
          </Flex>
          <Flex vAlign="center" hAlign="end" style={{ padding: "1rem 0" }} data-testid="table-pagination">
            <Button icon={<ChevronsLeft />} disabled={!logic.table.getCanPreviousPage()} onClick={logic.table.firstPage} text iconOnly />
            {logic.table.getState().pagination.pageIndex > 0 ? (
              <Button
                content={logic.table.getState().pagination.pageIndex}
                disabled={!logic.table.getCanPreviousPage()}
                onClick={logic.table.previousPage}
                text
                iconOnly
              />
            ) : (
              <div style={{ width: "32px" }}></div>
            )}
            <Button content={logic.table.getState().pagination.pageIndex + 1} primary text iconOnly />
            <Button
              content={logic.table.getState().pagination.pageIndex + 2}
              disabled={!logic.table.getCanNextPage()}
              onClick={logic.table.nextPage}
              text
              iconOnly
            />
            <Button icon={<ChevronsRight />} disabled={!logic.table.getCanNextPage()} onClick={logic.table.lastPage} text iconOnly />
          </Flex>
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default memo(Table);
