import { Alert, Button, CloseIcon, Dialog, ExclamationCircleIcon, Flex, Text } from "@fluentui/react-northstar";
import { memo, useMemo } from "react";
import { useImportContact } from "./ImportContact.logic";
import { Controller } from "react-hook-form";
import { primaryStyle } from "../../../../utils/primaryStyle";
import { translations } from "../../../../translations";
import { useTranslate } from "front";
import "./ImportContact.scss";

const ImportContact = memo(() => {
  const logic = useImportContact();
  const t = useTranslate(translations);

  const platinumUpgradeList = ["UnlimitedContacts", "AdvancedPermissions", "PowerAutomateConnector", "APIAccess"];

  const importContent = useMemo(
    () => (
      <>
        {logic.isLoading || logic.state ? (
          <Flex padding={"padding.medium"} column>
            <Text styles={{ marginBottom: "15px" }} content={t("RequestInProgress")} />
            <Text styles={{ marginBottom: "15px" }} content={t("ImportMessage")} />
          </Flex>
        ) : (
          <Flex column>
            <Controller
              name="fileInput"
              control={logic.control}
              rules={{
                validate: {
                  isExcelFile: (file) => logic.fileIsExcelFormat(file),
                },
              }}
              render={({ field }) => (
                <input
                  style={{ visibility: "hidden", position: "absolute" }}
                  type="file"
                  accept=".xls,.xlsx"
                  onChange={(e) => {
                    field.onChange(e.target.files![0]);
                  }}
                  ref={logic.inpRef}
                />
              )}
            />
            <Flex column gap="gap.medium">
              <Text content={t("DownloadModelRule1")} />
              <Button primary content={t("DownloadModel")} onClick={logic.handleDownloadModel} style={primaryStyle} />
              <Text content={t("DownloadModelRule2")} />
            </Flex>
          </Flex>
        )}
      </>
    ),
    [logic.isLoading, logic.state]
  );

  const importFooter = useMemo(
    () => (
      <>
        {logic.isLoading || logic.state ? (
          <Button disabled={logic.isLoading} loading={logic.isLoading} primary content={logic.t("Close")} onClick={logic.onClose} />
        ) : (
          <Flex hAlign="end" vAlign="center" gap="gap.medium">
            {logic.errors.fileInput && <Alert warning content={logic.errors.fileInput?.message} />}
            <Button
              disabled={logic.isLoading}
              primary
              content={t("ImportContact")}
              style={{ ...primaryStyle, marginRight: ".5rem" }}
              onClick={logic.openFiles}
            />
          </Flex>
        )}
      </>
    ),
    [logic.errors, logic.isLoading, logic.state]
  );

  const contactLimitIsReachedHeader = useMemo(
    () => (
      <Flex vAlign="center" gap="gap.small">
        <ExclamationCircleIcon outline />
        <Text content={logic.t("ContactLimitReached")} />
      </Flex>
    ),
    []
  );

  const contactLimitIsReachedContent = useMemo(
    () => (
      <Flex gap="gap.medium" column style={{ paddingTop: "2rem" }}>
        <Flex gap="gap.small" column>
          <Text content={logic.t("ContactLimitReachedTitle1")} weight="bold" />
          <Text content={logic.t("ContactLimitReachedText")} />
        </Flex>
        <Flex gap="gap.small" column>
          <Text content={logic.t("ContactLimitReachedTitle2")} weight="bold" />
          <Flex vAlign="start" column style={{ paddingLeft: "1rem" }}>
            {platinumUpgradeList.map((item, i) => (
              <Flex key={`${item}-${i}`} gap="gap.small" vAlign="center">
                <div className="importContact-bubble"></div>
                <Text content={logic.t(item)} />
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    ),
    []
  );

  const contactLimitIsReachedFooter = useMemo(
    () => (
      <Flex hAlign="end" vAlign="center" gap="gap.medium">
        <Button primary content={t("ViewPlans")} onClick={logic.openPremium} />
      </Flex>
    ),
    []
  );

  return (
    <Dialog
      header={logic.canAddContact ? t("StartImport") : contactLimitIsReachedHeader}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.onClose }}
      content={logic.canAddContact ? importContent : contactLimitIsReachedContent}
      footer={logic.canAddContact ? importFooter : contactLimitIsReachedFooter}
      defaultOpen={true}
      onCancel={logic.onClose}
      closeOnOutsideClick
    />
  );
});

export default ImportContact;
