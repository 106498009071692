export const NO_INPUT_VALUE = "-";
export const ALL_GROUPS_TAKE = 15;
export const DEFAULT_PAGE_INDEX = 0;
export const ALL_CONTACTS_TAKE = 100;
export const COUNTER_BEFORE_SEARCH = 1000;
export const CONTACT_PROPERTIES_MAX_LENGTH = 100;
export const CONTACT_URL_PROPERTIES_MAX_LENGTH = 500;
export const CONTACT_NOTES_MAX_LENGTH = 1000;
export const GROUP_NAME_MAX_LENGTH = 100;
export const PHONES_PROPERTIES_MAX_LENGTH = 20;
