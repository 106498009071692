import { QUERY_KEY_USERS } from "../../../const/queryKey";
import { handleExpiredToken, useAppConfigurationSelector, useMsTeamsSelector, useTrack } from "front";
import { IQueryName } from "../../../interfaces/IQueryName/IQueryName";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "../../../redux/store";
import { queryOptions } from "../queryOptions";
import { getAllContacts2 } from "../../../apis/actions/contactAction";
import { IContact } from "../../../interfaces/IContact/IContact";

export const useContacts = () => {
  const filter = useSelector((s: ReduxStoreState) => s.filter);
  const { appId } = useAppConfigurationSelector("appId");
  const { tenantId } = useMsTeamsSelector("tenantId");
  const { trackItAsync2 } = useTrack();

  const _queryContacts = useQuery(
    [QUERY_KEY_USERS, appId, tenantId, filter],
    trackItAsync2<{ total: number; contacts: IContact[] }, any>(IQueryName.QUERY_CONTACTS, getAllContacts2),
    {
      ...queryOptions,
      enabled: !!appId,
      staleTime: Infinity,
      refetchOnMount: false,
      keepPreviousData: true,
      ...handleExpiredToken,
    }
  );

  return { queryContacts: _queryContacts };
};
