import { Button, Flex, MenuButton, MoreIcon, Text, Tooltip, UserFriendsIcon } from "@fluentui/react-northstar";
import { ColumnDef, getCoreRowModel, OnChangeFn, SortingState, Updater, useReactTable } from "@tanstack/react-table";
import ContactButtons from "../components/ContactButtons/ContactButtons";
import { generateGUID, openModal, setModalWithData, usePermissionsSelector, useTranslate } from "front";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { useContacts } from "../hooks/useQuery/Contacts/useContacts.query";
import { IBreakpoint } from "../interfaces/IBreakpoint";
import { IContact } from "../interfaces/IContact/IContact";
import { IDialogs } from "../interfaces/IDialog/IDialogs";
import { IPagination } from "../interfaces/IPagination2/IPagination";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserToDelete } from "../redux/reducer/ContactListReducer";
import { setFilter } from "../redux/reducer/FilterReducer";
import { IAppDispatch, ReduxStoreState } from "../redux/store";
import { translations } from "../translations";

const defaultData = [] as IContact[];

export const useTable = () => {
  const t = useTranslate(translations);
  const dispatch = useDispatch<IAppDispatch>();
  const { breakpoint } = useBreakpoint();
  const { queryContacts } = useContacts();
  const { userPerm } = usePermissionsSelector("userPerm");
  const filter = useSelector((s: ReduxStoreState) => s.filter);

  const stableFilter = useMemo(() => filter, [filter]);

  const columns: ColumnDef<IContact, any>[] = useMemo(
    () => [
      {
        accessorKey: "",
        header: "",
        id: "icon",
        cell: () => <UserFriendsIcon style={{ paddingLeft: "1rem" }} />,
      },
      {
        accessorKey: "name",
        header: t("name"),
        cell: (props) => <Text content={props.getValue()} />,
        enableSorting: true,
      },
      {
        accessorKey: "jobTitle",
        header: t("jobTitle"),
        cell: (props) => <Text content={props.getValue()} />,
        enableSorting: true,
      },
      {
        accessorKey: "company",
        header: t("company"),
        cell: (props) => <Text content={props.getValue()} />,
        enableSorting: true,
      },
      {
        accessorKey: "department",
        header: t("Department"),
        cell: (props) => <Text content={props.getValue()} />,
        enableSorting: true,
      },
      {
        accessorKey: "phones.mobile",
        id: "mobile",
        header: t("mobile"),
        cell: (props) => <Text content={props.getValue()} />,
        enableSorting: false,
      },
      {
        accessorKey: "internet.email",
        id: "email",
        header: t("email"),
        cell: (props) => <Text content={props.getValue()} />,
        enableSorting: true,
      },
      {
        accessorKey: "",
        header: "",
        cell: (props) => {
          const contact = props.row.original;
          return (
            <Flex onClick={(e: any) => e.stopPropagation()} vAlign="center" hAlign="center">
              <ContactButtons userMail={contact.internet?.email ?? ""} phones={contact.phones} responsive />
              <MenuButton title="More" trigger={<Button icon={<MoreIcon />} iconOnly text />} menu={getMenu(contact)} on="click" />
            </Flex>
          );
        },
        id: "actions",
      },
    ],
    []
  );

  const handlePagination = (updater: Updater<IPagination>) => {
    const pagination = typeof updater === "function" ? updater(filter.pagination) : updater;
    dispatch(setFilter({ ...filter, pagination }));
  };

  const handleSort: OnChangeFn<SortingState> = (updater: Updater<SortingState>) => {
    const sortBy = typeof updater === "function" ? updater(filter.sortBy) : updater;
    dispatch(setFilter({ ...filter, sortBy }));
  };

  const table = useReactTable({
    data: queryContacts.data?.result?.contacts ?? defaultData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: Math.ceil((queryContacts.data?.result?.total ?? 0) / (stableFilter.pagination.pageSize || 1)),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: handlePagination,
    onSortingChange: handleSort,
    // debugTable: true,
    state: {
      pagination: stableFilter.pagination,
      sorting: stableFilter.sortBy,
      columnVisibility: {
        icon: true,
        name: true,
        jobTitle: breakpoint > IBreakpoint.SM,
        company: breakpoint > IBreakpoint.SM,
        department: breakpoint > IBreakpoint.MD,
        mobile: breakpoint > IBreakpoint.MD,
        email: breakpoint > IBreakpoint.MD,
        actions: true,
      },
    },
  });

  const openDeleteContactDialog = (contact: IContact) => {
    if (!contact) return;
    dispatch(openModal(IDialogs.DELETE_CONTACT));
    dispatch(setUserToDelete({ contact, isLonely: queryContacts.data?.result?.contacts.length === 1 }));
  };

  const editContact = (contactId: string, editMode: boolean) => {
    dispatch(setModalWithData({ isOpen: IDialogs.CONTACT, data: { edit: editMode, contact: { id: contactId } } }));
  };

  const getMenu = (contact: IContact) => {
    const menu = [
      {
        key: generateGUID(),
        disabled: !userPerm.perm.update,
        onClick: () => editContact(contact.id, true),
        content: (
          <>
            {userPerm.perm.update ? (
              <Flex vAlign="center" hAlign="start" gap="gap.small">
                <Text content={t("Edit")} />
              </Flex>
            ) : (
              <Tooltip
                content={t("NoEditPerm")}
                pointing
                trigger={
                  <Flex vAlign="center" hAlign="start" gap="gap.small">
                    <Text content={t("Edit")} />
                  </Flex>
                }
              />
            )}
          </>
        ),
      },
      {
        key: generateGUID(),
        disabled: !userPerm.perm.delete,
        onClick: () => openDeleteContactDialog(contact),
        content: (
          <>
            {userPerm.perm.delete ? (
              <Flex vAlign="center" hAlign="start" gap="gap.small">
                <Text content={t("Delete")} />
              </Flex>
            ) : (
              <Tooltip
                content={t("NoDeletePerm")}
                pointing
                trigger={
                  <Flex vAlign="center" hAlign="start" gap="gap.small">
                    <Text content={t("Delete")} />
                  </Flex>
                }
              />
            )}
          </>
        ),
      },
    ];

    return menu;
  };

  return {
    table,
    t,
    tableLength: queryContacts.data?.result?.total ?? 0,
    isLoading: queryContacts.isLoading,
    editContact,
  };
};
