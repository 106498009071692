import { IBreakpoint } from "../interfaces/IBreakpoint";

export const defaultBreakpoint = (innerWidth: number) => {
  if (innerWidth > IBreakpoint.XXL) return IBreakpoint.XXL;
  if (innerWidth > IBreakpoint.XL) return IBreakpoint.XL;
  if (innerWidth > IBreakpoint.LG) return IBreakpoint.LG;
  if (innerWidth > IBreakpoint.MD) return IBreakpoint.MD;
  if (innerWidth > IBreakpoint.SM) return IBreakpoint.SM;
  return IBreakpoint.XS;
};
