import React, { useState } from "react";
import { AppsIcon, CartIcon, ExcelColorIcon, Flex, PlugsIcon, SettingsIcon, ShieldKeyholeIcon, Text, UserFriendsIcon } from "@fluentui/react-northstar";
import { QUERY_KEY_CONTACT_LIST, QUERY_KEY_USERS } from "../../const/queryKey";
import { openModal, openPremiumDialog, useMsTeamsSelector, usePremiumSelector, useTranslate, setModalWithData, usePermissionsSelector } from "front";
import { useContactListCache } from "../../hooks/useQuery/useContactListCache";
import { useQueryCache } from "../../hooks/useQuery/useQueryCache";
import { IQueryCacheData } from "../../interfaces/ICache/IQueryCache";
import { IDialogs } from "../../interfaces/IDialog/IDialogs";
import { IDisplayMode } from "../../interfaces/IDisplayMode";
import { useCallback, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPagination } from "../../redux/reducer/PaginationReducer";
import { IAppDispatch, ReduxStoreState } from "redux/store";
import { translations } from "../../translations";
import { generateGUID } from "../../utils/generateGUID";
import { getDisplayMode } from "../../utils/getDisplayMode";
import { queryClient } from "../../index";
import { appInsightInstance } from "../../services/TelemetryService/TelemetryService";
import { CrownIcon } from "../../icons/Crown";
import { useContactPermissions } from "../../hooks/useContactPermissions/useContactPermissions";
import { COUNTER_BEFORE_SEARCH } from "../../const/const";
import { setFilter } from "../../redux/reducer/FilterReducer";

const CONNECTOR = "https://learn.microsoft.com/en-us/connectors/contactspro/";
const API_LINK = "https://developer.teams-pro.com/product#product=contact-pro-api";

export const useNavbar = () => {
  // Services
  const t = useTranslate(translations);
  const dispatch = useDispatch<IAppDispatch>();
  const navigate = useNavigate();
  const queryCache = useQueryCache();
  const { queryContactList } = useContactListCache();
  const pagination = useSelector((s: ReduxStoreState) => s.pagination);
  const filter = useSelector((s: ReduxStoreState) => s.filter);
  const appId = useSelector((s: ReduxStoreState) => s.appConfiguration.appId);
  const { isPremium, isPlatinum, loaded } = usePremiumSelector("isPremium", "isPlatinum", "loaded");
  const { isOnMobile } = useMsTeamsSelector("isOnMobile");
  const { userPerm } = usePermissionsSelector("userPerm");

  const openPremium = useCallback(() => {
    dispatch(openPremiumDialog(appInsightInstance));
  }, []);

  const openNewGroupDialog = useCallback(() => dispatch(setModalWithData({ isOpen: IDialogs.GROUP, data: { isEditing: false, group: undefined } })), []);

  const openExportContactDialog = useCallback(() => dispatch(openModal(IDialogs.EXPORT_CONTACT)), []);
  const openImportContactDialog = useCallback(() => dispatch(openModal(IDialogs.IMPORT_CONTACT)), []);
  const openSettingsContactDialog = useCallback(() => dispatch(openModal(IDialogs.SETTINGS_CONTACT)), []);
  const goToAdminGroup = useCallback(() => navigate("/groups/manage"), []);
  const goToPermission = useCallback(() => navigate("/permissions/" + queryContactList?.data?.id), [queryContactList]);
  const openUpgradeDialog = useCallback(() => dispatch(openModal(IDialogs.UPGRADE)), []);

  const { canAddContact } = useContactPermissions();

  const linkRedirection = (url: string) => window.open(url, "_blank");
  const { register, watch, control } = useForm<{ search: string }>();

  const onSubmit = () => {
    try {
      dispatch(
        setPagination({
          ...pagination,
          skip: 0,
          currentPage: 1,
          searchPattern: watch("search"),
        })
      );

      dispatch(setFilter({ ...filter, search: watch("search") }));

      queryClient.invalidateQueries([QUERY_KEY_USERS, appId]);

      !!appInsightInstance &&
        appInsightInstance.trackEvent({
          name: JSON.stringify({
            name: "Search",
            value: watch("search"),
            appId,
          }),
        });
    } catch (error) {
      console.error(error);
    }
  };

  const search = useWatch({
    control,
    defaultValue: { search: "" },
  });

  const createNewContact = useCallback(() => {
    dispatch(setModalWithData({ isOpen: IDialogs.CONTACT, data: { edit: true, contact: { id: "" } } }));
  }, []);

  useEffect(() => {
    if (!search) return;
    const timeout = setTimeout(() => {
      onSubmit();
    }, COUNTER_BEFORE_SEARCH);
    return () => clearTimeout(timeout);
  }, [search]);

  const setDisplayMode = (number: number) => {
    if (!queryContactList.data || queryContactList.data.defaultDisplayMode === number) return;
    const dataCache: IQueryCacheData = {
      data: { ...queryContactList.data, defaultDisplayMode: number },
      queryKey: [QUERY_KEY_CONTACT_LIST],
    };
    queryCache.mutation.mutate(dataCache);
  };

  const currentDisplayMode = useMemo((): IDisplayMode => {
    if (!queryContactList.data) return IDisplayMode.CARD;
    return getDisplayMode(queryContactList.data.defaultDisplayMode);
  }, [queryContactList.data]);

  const addNewMenu = [
    {
      key: generateGUID(),
      content: `${t("New")} ${t("Group")}`,
      onClick: openNewGroupDialog,
      styles: { fontSize: isOnMobile ? "1rem" : ".9rem" },
      disabled: !userPerm.perm.add,
    },
  ];
  const displayModeMenu = [
    {
      key: generateGUID(),
      content: t(IDisplayMode.CARD),
      onClick: () => setDisplayMode(0),
    },
    {
      key: generateGUID(),
      content: t(IDisplayMode.LIST),
      onClick: () => setDisplayMode(1),
    },
    {
      key: generateGUID(),
      content: t(IDisplayMode.GROUP),
      onClick: () => setDisplayMode(2),
    },
  ];
  const [moreMenu, setMoreMenu] = useState([
    {
      key: generateGUID(),
      icon: <SettingsIcon outline />,
      content: t("Settings"),
      onClick: openSettingsContactDialog,
    },
    {
      key: generateGUID(),
      icon: <UserFriendsIcon outline />,
      content: t("GroupsAdmin"),
      onClick: goToAdminGroup,
    },
    {
      key: generateGUID(),
      icon: <ExcelColorIcon outline />,
      content: (
        <Flex hAlign="center" vAlign="center" gap="gap.smaller">
          <Text content={t("ExportContact")} />
          {!isPremium && <CrownIcon permissions="premium" />}
        </Flex>
      ),
      onClick: isPremium ? openExportContactDialog : openPremium,
    },
    {
      key: generateGUID(),
      icon: <ExcelColorIcon outline />,
      content: (
        <Flex hAlign="center" vAlign="center" gap="gap.smaller">
          <Text content={t("ImportContact")} />
          {!isPremium && <CrownIcon permissions="premium" />}
        </Flex>
      ),
      onClick: isPremium ? openImportContactDialog : openPremium,
      disabled: !userPerm.perm.add,
    },
    {
      key: generateGUID(),
      content: (
        <Flex hAlign="center" vAlign="center" gap="gap.smaller">
          <Text content={t("permissions")} />
          {!isPlatinum && <CrownIcon permissions="platinium" />}
        </Flex>
      ),
      icon: <ShieldKeyholeIcon outline />,
      onClick: goToPermission,
    },
    {
      key: generateGUID(),
      content: (
        <Flex hAlign="center" vAlign="center" gap="gap.smaller">
          <Text content={t("connector")} />
          {!isPlatinum && <CrownIcon permissions="platinium" />}
        </Flex>
      ),
      icon: <AppsIcon outline />,
      onClick: () => {
        linkRedirection(CONNECTOR);
      },
    },
    {
      key: generateGUID(),
      content: (
        <Flex hAlign="center" vAlign="center" gap="gap.smaller">
          <Text content={"API"} />
          {!isPlatinum && <CrownIcon permissions="platinium" />}
        </Flex>
      ),
      icon: <PlugsIcon outline />,
      onClick: () => {
        linkRedirection(API_LINK);
      },
    },
  ]);

  const addUpgradeMenu = () => {
    let tmpMoreMenu = [...moreMenu];

    if (loaded && !isPlatinum && !moreMenu.some((m) => m.key == IDialogs.UPGRADE)) {
      tmpMoreMenu.push({
        key: IDialogs.UPGRADE,
        content: (
          <Flex hAlign="center" vAlign="center" gap="gap.smaller">
            <Text content={t("UpgradeToPaidPlan")} />
            <CrownIcon permissions={isPremium ? "platinium" : "premium"} />
          </Flex>
        ),
        icon: <CartIcon outline />,
        onClick: openUpgradeDialog,
      });

      setMoreMenu(tmpMoreMenu);
    }
  };

  useEffect(() => {
    addUpgradeMenu();
  }, [isPremium, isPlatinum, loaded]);

  const menus = useMemo(
    () => ({
      addNewMenu,
      displayModeMenu,
      moreMenu,
    }),
    [addNewMenu, displayModeMenu, moreMenu, isPremium, isPlatinum, loaded]
  );

  return { t, menus, currentDisplayMode, register, createNewContact, userPerm, canAddContact };
};
