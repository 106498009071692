import { isStringify, setModalWithData, useAppConfigurationSelector, useModalSelector, useMsTeamsSelector, useTranslate } from "front";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../../../redux/store";
import { translations } from "../../../../translations";
import { reducer, initialState, IAction } from "./ContactDialog.reducer";
import { ITabs } from "../../../../interfaces/IContact/ITabs";
import { IContact } from "../../../../interfaces/IContact/IContact";
import { SubmitHandler, useForm } from "react-hook-form";
import { useUsersCache } from "../../../../hooks/useQuery/useUsersCache";
import sanitizeHtml from "sanitize-html";
import { removeAllDuplicates } from "../../../../utils/removeAllDuplicates";
import { queryClient } from "../../../../index";
import { QUERY_KEY_PARTICIPANTS } from "../../../../const/queryKey";
import { IContactDialogRef } from "../../../../interfaces/IContactDialogRef/IContactDialogRef";
import { useContacts } from "../../../../hooks/useQuery/Contacts/useContacts.query";
import { resetFilter } from "../../../../redux/reducer/FilterReducer";

export const useContactDialog = () => {
  const { isLightTheme } = useMsTeamsSelector("isLightTheme");
  const { data } = useModalSelector("data");
  const { appId } = useAppConfigurationSelector("appId");
  const { tenantId } = useMsTeamsSelector("tenantId");
  const dispatchCtx = useDispatch<IAppDispatch>();
  const t = useTranslate(translations);

  // Refs
  const contactDialogRef = useRef<IContactDialogRef>();
  const onContactDialogRef = useCallback((ref: IContactDialogRef) => (contactDialogRef.current = ref), []);

  const [banner, setBanner] = useState<
    | {
        show: boolean;
        txt: string;
        confirmLabel: string | undefined;
        cancelLabel: string | undefined;
        cancelAction: (() => void) | undefined;
        confirmAction: (() => void) | undefined;
      }
    | undefined
  >(undefined);
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  const { queryUsers, mutateCreateContact, mutateEditContact, mutateDeleteContact } = useUsersCache();
  const { queryContacts } = useContacts();

  useEffect(() => {
    dispatch({
      type: IAction.CAN_MODIFY,
      payload: { ...state, canModify: data.edit },
    });
  }, [data]);

  useEffect(() => {
    if (data.contact.id) return;
    setTimeout(() => {
      trigger("name", { shouldFocus: true });
    }, 10);
  }, [data.contact.id]);

  useEffect(() => {
    if (!state.canModify) return;
    trigger();
  }, [state.canModify]);

  const getContact = useMemo(() => {
    if (!queryUsers.data?.contacts || !data.contact.id) return;
    const contact = queryUsers.data.contacts.find((u) => u.id === data.contact.id);
    if (!contact) return;
    return contact;
  }, [queryUsers.data, data.contact.id]);

  const defaultValues: any = {
    name: getContact?.name ?? "",
    contactListId: appId,
    groupId: {
      add: [],
      remove: [],
    },
    picture: getContact?.picture ?? "",
    jobTitle: getContact?.jobTitle ?? "",
    company: getContact?.company ?? "",
    department: getContact?.department ?? "",
    internet: {
      email: getContact?.internet?.email ?? "",
      website: getContact?.internet?.website ?? "",
      linkedin: getContact?.internet?.linkedin ?? "",
      facebook: getContact?.internet?.facebook ?? "",
      twitter: getContact?.internet?.twitter ?? "",
    },
    phones: {
      businessPhone: getContact?.phones?.businessPhone ?? "",
      mobile: getContact?.phones?.mobile ?? "",
      home: getContact?.phones?.home ?? "",
      businessFax: getContact?.phones?.businessFax ?? "",
    },
    addresses: [
      {
        fullAddress: getContact?.addresses ? getContact?.addresses[0]?.fullAddress ?? "" : "",
        lat: getContact?.addresses ? getContact?.addresses[0]?.lat ?? 0 : 0,
        lng: getContact?.addresses ? getContact?.addresses[0]?.lng ?? 0 : 0,
      },
    ],
    notes: getContact?.notes ? isStringify(getContact.notes) : "",
    objectType: "ContactEntity",
    createdBy: getContact?.createdBy ?? "",
    id: getContact?.id ?? "",
    isDeleted: getContact?.isDeleted ?? false,
    updatedBy: getContact?.updatedBy ?? "",
  };
  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm<IContact>({
    mode: "onChange",
    defaultValues,
  });

  const handleTabs = (v: ITabs) => {
    dispatch({
      type: IAction.MODIFY_TABS,
      payload: { ...state, currentTab: v },
    });
  };

  const cancelCloseDialog = () => {
    reset();
    contactDialogRef.current?.resetImage();
    dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
    setBanner(undefined);
  };

  const closeDialog = () => {
    if (state.canModify && isDirty) {
      openBanner(t("ConfirmCancelForm"), t("Reset"), t("ContinueEditing"), cancelCloseDialog, closeBanner);
    } else {
      dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
    }
  };

  const switchModify = (canModify: boolean, force?: boolean) => {
    if (!data.contact.id) return closeDialog();
    if (!canModify && isDirty && !force) return openBanner(t("ConfirmCancelForm"), t("Reset"), t("ContinueEditing"), cancelActionForm, closeBanner);
    if (!canModify) {
      reset();
      contactDialogRef.current?.resetImage();
    }
    dispatch({ type: IAction.CAN_MODIFY, payload: { ...state, canModify } });
  };

  const handleSuccess = () => {
    openBanner(t("Success"), undefined, undefined);
    setTimeout(() => {
      setBanner(undefined);
    }, 3000);
  };

  const onSubmit: SubmitHandler<any> = async (formData: any) => {
    formData.notes = JSON.stringify(
      sanitizeHtml(formData.notes, {
        allowedTags: sanitizeHtml.defaults.allowedTags,
      })
    );

    if (data.contact.id) {
      // EDIT
      const initialGroups = getContact?.groupId?.split(", ") ?? [];
      const groupRest = initialGroups.filter((element) => !formData.groupId.remove.includes(element));
      const groups = removeAllDuplicates([...groupRest, ...formData.groupId.add]);

      const params = {
        contactListId: appId,
        contactId: data.contact.id,
        contact: { ...formData, groupId: groups.join(", ") },
      };

      await mutateEditContact.mutateAsync(params, {
        onSuccess: () => {
          dispatch({
            type: IAction.CAN_MODIFY,
            payload: { ...state, canModify: false },
          });

          // Update queryParticipant cache
          groups.forEach((g) => {
            queryClient.setQueryData([QUERY_KEY_PARTICIPANTS, appId, g, tenantId], (oldData: IContact[] | undefined) => {
              if (!oldData) return [] as IContact[];
              const updatedContact = oldData.map((a) => (a.id === data.contact.id ? { ...a, ...params.contact } : a));
              return updatedContact;
            });
          });

          handleSuccess();
        },
      });
    } else {
      // CREATE
      const params: { contactListId: string; contact: IContact } = {
        contactListId: appId,
        contact: { ...formData, groupId: formData.groupId.add.join(", "), isDisabled: false },
      };
      await mutateCreateContact.mutateAsync(params, {
        onSuccess: () => {
          dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
          handleSuccess();
        },
      });
    }
    const allModifiedGroups = removeAllDuplicates([...formData.groupId.add, ...formData.groupId.remove]);

    for (const g of allModifiedGroups) {
      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY_PARTICIPANTS, appId, g],
        exact: true,
      });
    }

    // Todo: refect without refetch all
    queryContacts.refetch();
    dispatchCtx(resetFilter());
  };

  const closeBanner = useCallback(() => setBanner(undefined), []);

  const cancelActionForm = () => {
    setBanner(undefined);
    reset();
    contactDialogRef.current?.resetImage();
    switchModify(false, true);
  };

  const confirmActionDelete = async () => {
    await mutateDeleteContact.mutateAsync({
      contactListId: appId,
      contactId: data.contact.id,
      isLonely: queryUsers.data?.contacts.length === 1,
    });
    closeDialog();
  };

  const openBanner = (
    txt: string,
    cancelLabel: string | undefined,
    confirmLabel: string | undefined,
    cancelAction?: (() => void) | undefined,
    confirmAction?: (() => void) | undefined
  ) => {
    setBanner({
      show: true,
      txt,
      cancelLabel,
      confirmLabel,
      cancelAction,
      confirmAction,
    });
  };

  const displayDeleteContactBanner = () => openBanner(t("DeleteContact"), t("Cancel"), t("Confirm"), closeBanner, confirmActionDelete);

  return {
    ...state,
    contact: getContact,
    closeDialog,
    t,
    handleTabs,
    switchModify,
    control,
    register,
    handleSubmit,
    onSubmit,
    defaultValues,
    errors,
    trigger,
    displayDeleteContactBanner,
    setValue,
    watch,
    isValid,
    isDirty,
    isLightTheme,
    banner,
    onContactDialogRef,
    isLoading: mutateCreateContact.isLoading || mutateEditContact.isLoading,
  };
};
